@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      #2a7ae2 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima-chausse/base",
  "minima-chausse/layout",
  "minima-chausse/syntax-highlighting"
;

/* Chausse Stuff */

html body {
  background-color:#000;
}

body {
  height:100%;
  font-family: canada-type-gibson, -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Helvetica Neue", Arial, sans-serif;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight:300;
}

body em {
  font-weight:300;
}

.page-content {
  background-color:#fff;
}

body p {
  margin-bottom:30px;
}

body b, body strong {
  font-weight:500;
}

iframe {
  max-width:100%;
}

.post-list-heading, h1.post-title, h1, h2 {
  font-family: Jubilat, Bookman, sans-serif;
  font-weight:bold;
  letter-spacing:normal;
  color: #05293D;
}

.post-list-heading, h1.post-title, h1 {
  font-size: 60px;
  line-height: 60px;
}

h2, .post-content h2 {
  font-size:40px;
  line-height:40px;
  margin-bottom:25px;
  margin-top:80px;
}

h3 {
  color:#666;
  font-size:24px;
  margin-bottom:40px;
}

a {
  color:#A84C47;
  font-weight:500;
}

a:hover {
  text-decoration:none;
}

.site-header {
  background-color: #000;
  border-top: 0px solid #424242;
  border-bottom:0;
  height:64px;
}

.site-header a.site-title {
  font-family: canada-type-gibson, futura-pt, sans-serif;
  font-size:24px;
  line-height:28px;
  text-transform: uppercase;
  font-weight:500;
  color:#ffffff;
  text-decoration:none;
  letter-spacing:1px;
  padding-top:19px;

}

.site-header a.site-title img {
  height:28px;
  margin-right:10px;
  vertical-align:middle;
  display:block;
  float:left;
  margin-top:-2px;
}

.site-header a.site-title .subtitle {
  color:#ccc;
  font-weight:normal;
}

.site-nav .page-link {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

.home-hero {
  background-image: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0) 25%), url(/assets/homehero.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 200px;
  text-align: center;
}

.page-content {
  padding:0;
}

.page-content .wrapper {
  margin-top:50px;
}

.post-meta {
  display:inline-block;
  /*background-color: #BBBBBB;*/
  color: #666;
  ///padding:0 12px;
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Helvetica Neue", Arial, sans-serif;
  //border-radius: 50px;
  font-size:14px;
  margin:16px 0 0 0;
}

.page-link {
  display:inline-block;
  font-family: canada-type-gibson, futura-pt, sans-serif;
  text-transform: lowercase;
  padding-top:17px;
  font-weight:400;
  letter-spacing:0.5px;
}

.site-footer {
  border:0;
  background-color:#efefef;
  font-family: canada-type-gibson, -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Helvetica Neue", Arial, sans-serif;
  padding-top:40px;
}

.footer-col-wrapper {
  color:#666;
  line-height: 1.6;
  font-size:20px;
  font-weight:300;
}

.post-content {
  margin-bottom: 120px;
}

.home-intro {
    margin:40px 0 60px 0;
    font-family: canada-type-gibson, -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Helvetica Neue", Arial, sans-serif;
    font-weight:300;
}

.site-nav {
  background-color:#000;
  border-width:2px;
  top:12px;
}

@media screen and (max-width: 600px) {
  .post-list-heading, h1.post-title {
    font-size: 40px;
    line-height: 40px;
  }

  .site-nav .menu-icon > svg {
      fill: rgba(255, 255, 255, 0.9);
  }
}

.PageNavigation {
  font-size: 18px;
  display: block;
  width: auto;
  overflow: hidden;
  line-height:26px;
}

.blogPrev, .blogNext {
  display: block;
  width: 50%;
  float: left;
  margin: 1em 0;
}

.nextHead, prevHead {
  font-size:12px;
  color:#666;
  text-transform:uppercase;
  font-weight:500;
}

.blogNext {
  text-align:right;
}

.PageNavigation .next {
  text-align: right;
}

pre, code {
  line-height:20px;
}

.post-content p img {
  display:block;
  margin:60px auto;
}

blockquote {
  letter-spacing:0;
}

.PageNavigation {
  border-top: 1px solid #ccc;
}

.home {
  margin-bottom:120px;
}

a.button {
  background-color:#A84C47;
  color:#fff;
  font-weight:400;
  padding:12px 30px 10px 30px;
  border-radius:50px;
}

a.button:hover {
  background-color: darken(#A84C47,15%);
}

.post-list {
  margin-bottom:80px;
}

.gallery {
    text-align: center;
}

.gallery img {
    max-height: 200px;
    display: inline;
    max-width: 80%;
    margin: 10px;
    box-shadow: 0px 0px 20px 0px #999999;
}
